<template>
  <div class="root-box">
    <el-button type="primary" @click="articleAdd">添加</el-button>
        <el-table :data="articles" style="width: 90%" border>
          <el-table-column label="ID" prop="id" ></el-table-column>
          <el-table-column label="TITLE" prop="title" ></el-table-column>
          <el-table-column label="CREATED" prop="created_at" ></el-table-column>
          <el-table-column label="PUDATED" prop="updated_at" ></el-table-column>
          <el-table-column label="STATUS" width="100">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.status == 1">未发布</el-tag>
              <el-tag type="primary" v-if="scope.row.status == 2">已发布</el-tag>
              <el-tag type="info" v-if="scope.row.status == 3">已下架</el-tag>
              <el-tag type="black" v-if="scope.row.status == 0">已删除</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="OPS" width="300">
            <template slot-scope="scope">
            <el-button
          size="mini"
          v-if="scope.row.status == 2"
          @click="articleDepublish(scope.row)">RETRACT</el-button>
        <el-button
        v-else
          size="mini"
          type="primary"
          @click="articlePublish(scope.row)">PUBLISH</el-button>
        <el-button
          size="mini"
          type="warning"
          @click="articleEdit(scope.row)">EDIT</el-button>
          <el-button
          size="mini"
          type="danger"
          @click="articleDelete(scope.row)">DELETE</el-button>
      </template>
      </el-table-column>
        </el-table>
  </div>
</template>
<script>

export default {
  name: 'AdminArticle',
  data () {
    return {
      articles: [],
    }
  },
  methods: {
    articleAdd () {
      this.$router.push({ path: '/admin/article/add' })
    },
    getArticles () {
      this.$axios.get('/api/article')
        .then(res => { this.articles = res.data })
        .catch(err => console.log(err))
    },
    articlePublish (item) {
      this.$axios.post('/api/article/publish/' + item.id)
        .then(() => { item.status = 2 })
        .catch(err => console.log(err))
    },
    articleDepublish (item) {
      this.$axios.post('/api/article/depublish/' + item.id)
        .then(() => { item.status = 3 })
        .catch(err => console.log(err))
    },
    articleEdit (item) {
      this.$router.push({ path: '/admin/article/edit', query: { id: item.id } })
    },
    articleDelete (item) {
      this.$axios.delete('/api/article/' + item.id)
        .then(() => { item.status = 0 })
        .catch(err => console.log(err))
    }
  },
  mounted () {
    this.getArticles()
  }
}
</script>
<style lang="stylus" scoped>
.root-box
  margin-left: 20px
  margin-bottom: 50px
</style>
